import { default as _91problemId_93TIWE5nEhx9Meta } from "/workspace/pages/[category]/[manufacturerId]/[deviceId]/index/[problemId].vue?macro=true";
import { default as indexKUzsVjAk1WMeta } from "/workspace/pages/[category]/[manufacturerId]/[deviceId]/index/index.vue?macro=true";
import { default as indexl7Cifa8KLXMeta } from "/workspace/pages/[category]/[manufacturerId]/[deviceId]/index.vue?macro=true";
import { default as indexXsOwwe5BJlMeta } from "/workspace/pages/baza-usterek/[category]/[deviceUri]/[problemUri]/index.vue?macro=true";
import { default as indexHM5BwjjFppMeta } from "/workspace/pages/baza-usterek/[category]/[deviceUri]/index.vue?macro=true";
import { default as indexeNjgAAFGxxMeta } from "/workspace/pages/baza-usterek/[category]/index.vue?macro=true";
import { default as indexHl6d6QIKayMeta } from "/workspace/pages/baza-usterek/index.vue?macro=true";
import { default as confirmationpNupc6pCIRMeta } from "/workspace/pages/confirmation.vue?macro=true";
import { default as _91manufacturerId_93IXYCpmpczOMeta } from "/workspace/pages/index/index/[category]/[manufacturerId].vue?macro=true";
import { default as indexzWuELA90kvMeta } from "/workspace/pages/index/index/[category]/index.vue?macro=true";
import { default as _91category_93gFXdF9Bka5Meta } from "/workspace/pages/index/index/[category].vue?macro=true";
import { default as indexXdsLKFR9w4Meta } from "/workspace/pages/index/index/index.vue?macro=true";
import { default as indexGKsDTk3vIBMeta } from "/workspace/pages/index/index.vue?macro=true";
import { default as _91_46_46_46slug_93pwXSrkVbmkMeta } from "/workspace/pages/legal/[...slug].vue?macro=true";
import { default as indexao2Bn8PW9pMeta } from "/workspace/pages/naprawa/[deviceUri]/index.vue?macro=true";
import { default as indexk52iwUlc5VMeta } from "/workspace/pages/naprawa/index.vue?macro=true";
import { default as not_45foundmsvw9PaosCMeta } from "/workspace/pages/not-found.vue?macro=true";
import { default as index8fGbmhzH7bMeta } from "/workspace/pages/serwisy/[servicePointUri]/index.vue?macro=true";
import { default as indexFCSNPBXjR1Meta } from "/workspace/pages/serwisy/[servicePointUri]/naprawa-[deviceUri]/index.vue?macro=true";
import { default as naprawa_45_91deviceUri_935aT2JH8eNPMeta } from "/workspace/pages/serwisy/[servicePointUri]/naprawa-[deviceUri].vue?macro=true";
import { default as indexT4eb3VvtIhMeta } from "/workspace/pages/serwisy/[servicePointUri]/usluga-[category]-[problemUri]/index.vue?macro=true";
import { default as usluga_45_91category_93_45_91problemUri_93lou3IhmWY8Meta } from "/workspace/pages/serwisy/[servicePointUri]/usluga-[category]-[problemUri].vue?macro=true";
import { default as index9SnEHJ6G9KMeta } from "/workspace/pages/serwisy/index.vue?macro=true";
import { default as _91cityUri_930yg90FM4OfMeta } from "/workspace/pages/uslugi/[category]/[problemUri]/[cityUri].vue?macro=true";
import { default as indexIcYzxlEjN3Meta } from "/workspace/pages/uslugi/[category]/[problemUri]/index.vue?macro=true";
import { default as _91problemUri_93PaNdHvYRQ2Meta } from "/workspace/pages/uslugi/[category]/[problemUri].vue?macro=true";
import { default as indexy8VfJwnbs6Meta } from "/workspace/pages/uslugi/[category]/index.vue?macro=true";
import { default as indexj7R5W38XLpMeta } from "/workspace/pages/uslugi/index.vue?macro=true";
import { default as component_45stubEHNsUnIj6jMeta } from "/workspace/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubEHNsUnIj6j } from "/workspace/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexl7Cifa8KLXMeta?.name ?? undefined,
    path: indexl7Cifa8KLXMeta?.path ?? "/:category()/:manufacturerId()/:deviceId()",
    meta: indexl7Cifa8KLXMeta || {},
    alias: indexl7Cifa8KLXMeta?.alias || [],
    redirect: indexl7Cifa8KLXMeta?.redirect,
    component: () => import("/workspace/pages/[category]/[manufacturerId]/[deviceId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91problemId_93TIWE5nEhx9Meta?.name ?? "category-manufacturerId-deviceId-index-problemId",
    path: _91problemId_93TIWE5nEhx9Meta?.path ?? ":problemId()",
    meta: _91problemId_93TIWE5nEhx9Meta || {},
    alias: _91problemId_93TIWE5nEhx9Meta?.alias || [],
    redirect: _91problemId_93TIWE5nEhx9Meta?.redirect,
    component: () => import("/workspace/pages/[category]/[manufacturerId]/[deviceId]/index/[problemId].vue").then(m => m.default || m)
  },
  {
    name: indexKUzsVjAk1WMeta?.name ?? "category-manufacturerId-deviceId-index",
    path: indexKUzsVjAk1WMeta?.path ?? "",
    meta: indexKUzsVjAk1WMeta || {},
    alias: indexKUzsVjAk1WMeta?.alias || [],
    redirect: indexKUzsVjAk1WMeta?.redirect,
    component: () => import("/workspace/pages/[category]/[manufacturerId]/[deviceId]/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexXsOwwe5BJlMeta?.name ?? "baza-usterek-category-deviceUri-problemUri",
    path: indexXsOwwe5BJlMeta?.path ?? "/baza-usterek/:category()/:deviceUri()/:problemUri()",
    meta: indexXsOwwe5BJlMeta || {},
    alias: indexXsOwwe5BJlMeta?.alias || [],
    redirect: indexXsOwwe5BJlMeta?.redirect,
    component: () => import("/workspace/pages/baza-usterek/[category]/[deviceUri]/[problemUri]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHM5BwjjFppMeta?.name ?? "baza-usterek-category-deviceUri",
    path: indexHM5BwjjFppMeta?.path ?? "/baza-usterek/:category()/:deviceUri()",
    meta: indexHM5BwjjFppMeta || {},
    alias: indexHM5BwjjFppMeta?.alias || [],
    redirect: indexHM5BwjjFppMeta?.redirect,
    component: () => import("/workspace/pages/baza-usterek/[category]/[deviceUri]/index.vue").then(m => m.default || m)
  },
  {
    name: indexeNjgAAFGxxMeta?.name ?? "baza-usterek-category",
    path: indexeNjgAAFGxxMeta?.path ?? "/baza-usterek/:category()",
    meta: indexeNjgAAFGxxMeta || {},
    alias: indexeNjgAAFGxxMeta?.alias || [],
    redirect: indexeNjgAAFGxxMeta?.redirect,
    component: () => import("/workspace/pages/baza-usterek/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHl6d6QIKayMeta?.name ?? "baza-usterek",
    path: indexHl6d6QIKayMeta?.path ?? "/baza-usterek",
    meta: indexHl6d6QIKayMeta || {},
    alias: indexHl6d6QIKayMeta?.alias || [],
    redirect: indexHl6d6QIKayMeta?.redirect,
    component: () => import("/workspace/pages/baza-usterek/index.vue").then(m => m.default || m)
  },
  {
    name: confirmationpNupc6pCIRMeta?.name ?? "confirmation",
    path: confirmationpNupc6pCIRMeta?.path ?? "/confirmation",
    meta: confirmationpNupc6pCIRMeta || {},
    alias: confirmationpNupc6pCIRMeta?.alias || [],
    redirect: confirmationpNupc6pCIRMeta?.redirect,
    component: () => import("/workspace/pages/confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexGKsDTk3vIBMeta?.name ?? undefined,
    path: indexGKsDTk3vIBMeta?.path ?? "/",
    meta: indexGKsDTk3vIBMeta || {},
    alias: indexGKsDTk3vIBMeta?.alias || [],
    redirect: indexGKsDTk3vIBMeta?.redirect,
    component: () => import("/workspace/pages/index/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93gFXdF9Bka5Meta?.name ?? undefined,
    path: _91category_93gFXdF9Bka5Meta?.path ?? ":category()",
    meta: _91category_93gFXdF9Bka5Meta || {},
    alias: _91category_93gFXdF9Bka5Meta?.alias || [],
    redirect: _91category_93gFXdF9Bka5Meta?.redirect,
    component: () => import("/workspace/pages/index/index/[category].vue").then(m => m.default || m),
    children: [
  {
    name: _91manufacturerId_93IXYCpmpczOMeta?.name ?? "index-index-category-manufacturerId",
    path: _91manufacturerId_93IXYCpmpczOMeta?.path ?? ":manufacturerId()",
    meta: _91manufacturerId_93IXYCpmpczOMeta || {},
    alias: _91manufacturerId_93IXYCpmpczOMeta?.alias || [],
    redirect: _91manufacturerId_93IXYCpmpczOMeta?.redirect,
    component: () => import("/workspace/pages/index/index/[category]/[manufacturerId].vue").then(m => m.default || m)
  },
  {
    name: indexzWuELA90kvMeta?.name ?? "index-index-category",
    path: indexzWuELA90kvMeta?.path ?? "",
    meta: indexzWuELA90kvMeta || {},
    alias: indexzWuELA90kvMeta?.alias || [],
    redirect: indexzWuELA90kvMeta?.redirect,
    component: () => import("/workspace/pages/index/index/[category]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexXdsLKFR9w4Meta?.name ?? "index-index",
    path: indexXdsLKFR9w4Meta?.path ?? "",
    meta: indexXdsLKFR9w4Meta || {},
    alias: indexXdsLKFR9w4Meta?.alias || [],
    redirect: indexXdsLKFR9w4Meta?.redirect,
    component: () => import("/workspace/pages/index/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_46_46_46slug_93pwXSrkVbmkMeta?.name ?? "legal-slug",
    path: _91_46_46_46slug_93pwXSrkVbmkMeta?.path ?? "/legal/:slug(.*)*",
    meta: _91_46_46_46slug_93pwXSrkVbmkMeta || {},
    alias: _91_46_46_46slug_93pwXSrkVbmkMeta?.alias || [],
    redirect: _91_46_46_46slug_93pwXSrkVbmkMeta?.redirect,
    component: () => import("/workspace/pages/legal/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexao2Bn8PW9pMeta?.name ?? "naprawa-deviceUri",
    path: indexao2Bn8PW9pMeta?.path ?? "/naprawa/:deviceUri()",
    meta: indexao2Bn8PW9pMeta || {},
    alias: indexao2Bn8PW9pMeta?.alias || [],
    redirect: indexao2Bn8PW9pMeta?.redirect,
    component: () => import("/workspace/pages/naprawa/[deviceUri]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk52iwUlc5VMeta?.name ?? "naprawa",
    path: indexk52iwUlc5VMeta?.path ?? "/naprawa",
    meta: indexk52iwUlc5VMeta || {},
    alias: indexk52iwUlc5VMeta?.alias || [],
    redirect: indexk52iwUlc5VMeta?.redirect,
    component: () => import("/workspace/pages/naprawa/index.vue").then(m => m.default || m)
  },
  {
    name: not_45foundmsvw9PaosCMeta?.name ?? "not-found",
    path: not_45foundmsvw9PaosCMeta?.path ?? "/not-found",
    meta: not_45foundmsvw9PaosCMeta || {},
    alias: not_45foundmsvw9PaosCMeta?.alias || [],
    redirect: not_45foundmsvw9PaosCMeta?.redirect,
    component: () => import("/workspace/pages/not-found.vue").then(m => m.default || m)
  },
  {
    name: index8fGbmhzH7bMeta?.name ?? "serwisy-servicePointUri",
    path: index8fGbmhzH7bMeta?.path ?? "/serwisy/:servicePointUri()",
    meta: index8fGbmhzH7bMeta || {},
    alias: index8fGbmhzH7bMeta?.alias || [],
    redirect: index8fGbmhzH7bMeta?.redirect,
    component: () => import("/workspace/pages/serwisy/[servicePointUri]/index.vue").then(m => m.default || m)
  },
  {
    name: naprawa_45_91deviceUri_935aT2JH8eNPMeta?.name ?? undefined,
    path: naprawa_45_91deviceUri_935aT2JH8eNPMeta?.path ?? "/serwisy/:servicePointUri()/naprawa-:deviceUri()",
    meta: naprawa_45_91deviceUri_935aT2JH8eNPMeta || {},
    alias: naprawa_45_91deviceUri_935aT2JH8eNPMeta?.alias || [],
    redirect: naprawa_45_91deviceUri_935aT2JH8eNPMeta?.redirect,
    component: () => import("/workspace/pages/serwisy/[servicePointUri]/naprawa-[deviceUri].vue").then(m => m.default || m),
    children: [
  {
    name: indexFCSNPBXjR1Meta?.name ?? "serwisy-servicePointUri-naprawa-deviceUri",
    path: indexFCSNPBXjR1Meta?.path ?? "",
    meta: indexFCSNPBXjR1Meta || {},
    alias: indexFCSNPBXjR1Meta?.alias || [],
    redirect: indexFCSNPBXjR1Meta?.redirect,
    component: () => import("/workspace/pages/serwisy/[servicePointUri]/naprawa-[deviceUri]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usluga_45_91category_93_45_91problemUri_93lou3IhmWY8Meta?.name ?? undefined,
    path: usluga_45_91category_93_45_91problemUri_93lou3IhmWY8Meta?.path ?? "/serwisy/:servicePointUri()/usluga-:category()-:problemUri()",
    meta: usluga_45_91category_93_45_91problemUri_93lou3IhmWY8Meta || {},
    alias: usluga_45_91category_93_45_91problemUri_93lou3IhmWY8Meta?.alias || [],
    redirect: usluga_45_91category_93_45_91problemUri_93lou3IhmWY8Meta?.redirect,
    component: () => import("/workspace/pages/serwisy/[servicePointUri]/usluga-[category]-[problemUri].vue").then(m => m.default || m),
    children: [
  {
    name: indexT4eb3VvtIhMeta?.name ?? "serwisy-servicePointUri-usluga-category-problemUri",
    path: indexT4eb3VvtIhMeta?.path ?? "",
    meta: indexT4eb3VvtIhMeta || {},
    alias: indexT4eb3VvtIhMeta?.alias || [],
    redirect: indexT4eb3VvtIhMeta?.redirect,
    component: () => import("/workspace/pages/serwisy/[servicePointUri]/usluga-[category]-[problemUri]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index9SnEHJ6G9KMeta?.name ?? "serwisy",
    path: index9SnEHJ6G9KMeta?.path ?? "/serwisy",
    meta: index9SnEHJ6G9KMeta || {},
    alias: index9SnEHJ6G9KMeta?.alias || [],
    redirect: index9SnEHJ6G9KMeta?.redirect,
    component: () => import("/workspace/pages/serwisy/index.vue").then(m => m.default || m)
  },
  {
    name: _91problemUri_93PaNdHvYRQ2Meta?.name ?? undefined,
    path: _91problemUri_93PaNdHvYRQ2Meta?.path ?? "/uslugi/:category()/:problemUri()",
    meta: _91problemUri_93PaNdHvYRQ2Meta || {},
    alias: _91problemUri_93PaNdHvYRQ2Meta?.alias || [],
    redirect: _91problemUri_93PaNdHvYRQ2Meta?.redirect,
    component: () => import("/workspace/pages/uslugi/[category]/[problemUri].vue").then(m => m.default || m),
    children: [
  {
    name: _91cityUri_930yg90FM4OfMeta?.name ?? "uslugi-category-problemUri-cityUri",
    path: _91cityUri_930yg90FM4OfMeta?.path ?? ":cityUri()",
    meta: _91cityUri_930yg90FM4OfMeta || {},
    alias: _91cityUri_930yg90FM4OfMeta?.alias || [],
    redirect: _91cityUri_930yg90FM4OfMeta?.redirect,
    component: () => import("/workspace/pages/uslugi/[category]/[problemUri]/[cityUri].vue").then(m => m.default || m)
  },
  {
    name: indexIcYzxlEjN3Meta?.name ?? "uslugi-category-problemUri",
    path: indexIcYzxlEjN3Meta?.path ?? "",
    meta: indexIcYzxlEjN3Meta || {},
    alias: indexIcYzxlEjN3Meta?.alias || [],
    redirect: indexIcYzxlEjN3Meta?.redirect,
    component: () => import("/workspace/pages/uslugi/[category]/[problemUri]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexy8VfJwnbs6Meta?.name ?? "uslugi-category",
    path: indexy8VfJwnbs6Meta?.path ?? "/uslugi/:category()",
    meta: indexy8VfJwnbs6Meta || {},
    alias: indexy8VfJwnbs6Meta?.alias || [],
    redirect: indexy8VfJwnbs6Meta?.redirect,
    component: () => import("/workspace/pages/uslugi/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexj7R5W38XLpMeta?.name ?? "uslugi",
    path: indexj7R5W38XLpMeta?.path ?? "/uslugi",
    meta: indexj7R5W38XLpMeta || {},
    alias: indexj7R5W38XLpMeta?.alias || [],
    redirect: indexj7R5W38XLpMeta?.redirect,
    component: () => import("/workspace/pages/uslugi/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubEHNsUnIj6jMeta?.name ?? undefined,
    path: component_45stubEHNsUnIj6jMeta?.path ?? "/sitemap.xml",
    meta: component_45stubEHNsUnIj6jMeta || {},
    alias: component_45stubEHNsUnIj6jMeta?.alias || [],
    redirect: component_45stubEHNsUnIj6jMeta?.redirect,
    component: component_45stubEHNsUnIj6j
  }
]