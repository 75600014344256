export function getDevices(params: GetDevicesParams) {
  const { $api } = useNuxtApp()
  return $api<PaginatedResult<DeviceDetail>>('/devices/', { method: 'GET', params });
}

export function getGroupedDevicesWithRepairServices() {
  const { $api } = useNuxtApp()
  return $api<GroupedDevices[]>('/grouped-devices-having-repair-services/', { method: 'GET' });
}

export function getGroupedDevicesWithArticles() {
  const { $api } = useNuxtApp()
  return $api<GroupedDevices[]>('/grouped-devices-having-articles/', { method: 'GET' });
}

export function getDeviceByUri(uri: string) {
  const { $api } = useNuxtApp()
  return $api<DeviceDetail>(`/devices/${uri}/`, { method: 'GET' });
}
